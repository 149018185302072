.order-details-next-button {
  width: 11.25rem;
}

.order-details-action {
  color: #0d62ff;
}

.order-details-loading {
  padding-top: 2.75rem;
}

.order-details-stepper {
  padding-top: 1.5rem;
}

.order-details-pricing {
  margin-top: 1.75rem;
}

.order-details-contact-us {
  background-color: #f7f7f7;
  text-align: center;
  padding: 1.25rem;
  margin: 3.813rem 0 4rem;
}

.order-details-contact-us-link a {
  color: #000;
}

.order-details-contact-us-link a:hover {
  color: #0a4ecc;
}

@media screen and (max-width: 1023px) {
  .order-details-loading {
    padding-top: 2.25rem;
  }
}

@media screen and (max-width: 767px) {
  .order-details-next-button {
    width: 9.5rem;
  }

  .order-details-loading {
    padding-top: 1.75rem;
  }

  .order-details-contact-us {
    background-color: #f7f7f7;
    text-align: center;
    padding: 1rem;
    margin: 1.75rem 0 3.25rem;
  }
}
