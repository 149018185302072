.table {
  @apply text-left w-full;
}

.table-body {
  display: block;
  margin: 0 4.016rem;
}

@media screen and (max-width: 767px) {
  .table-body {
    margin: 0;
  }
}
