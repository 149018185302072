.legal-agreement-header {
  flex-direction: row;
  margin-bottom: 0.5rem;
  display: flex;
}

.download-print {
  display: flex;
  margin-left: auto;
  color: #0d62ff;
}

.legal-checkbox-main {
  margin-top: 2.25rem;
}

s .icon2 {
  padding-left: 0.625rem;
}

.text-area-main {
  overflow-y: scroll;
  border: 1px solid rgb(204 204 204);
  padding-left: 1.5rem;
  padding-right: 2.688rem;
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
  height: auto;
  max-height: 9.25rem;
}

.legal-agreement {
  flex-direction: column;
  padding-bottom: 2.25rem;
}

.legal-print-icon {
  margin-left: 1.25rem;
}

@media screen and (min-width: 681px) {
  .legal-agreement {
    flex-direction: row;
  }
}

@media screen and (max-width: 680px) {
  .title {
    margin-bottom: 0.125rem;
  }

  .legal-agreement-header {
    flex-direction: column;
  }

  .legal-agreement {
    margin: 0;
    padding-bottom: 2rem;
  }

  .download-print {
    margin: 0.4rem 0 0.4rem 0;
  }

  .text-area-main {
    padding-right: 0.375rem;
  }

  .legal-checkbox {
    margin-top: 0.438rem;
  }
}
