.error-block {
  height: 100%;
  display: flex;
  flex-direction: column;
}

.error-text-centered {
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex-grow: 1;
  text-align: center;
  width: 100vw;
  height: 100vh;
}

.error-text {
  width: 590px;
}

.error-text-heading {
  display: block;
  margin-bottom: 24px;
}

.screen-reader {
  position: absolute !important;
  height: 1px;
  overflow: hidden;
  clip: rect(1px, 1px, 1px, 1px);
}

@media screen and (max-width: 1023px) {
  .error-text {
    width: 540px;
  }

  .error-text-heading {
    margin-bottom: 20px;
  }
}

@media screen and (max-width: 767px) {
  .error-text {
    width: inherit;
    padding: 0 24px;
  }

  .error-text-heading {
    margin-bottom: 16px;
  }
}
