.frictionless-loading-holder {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.75);
  z-index: 10000;
  height: 100vh;
}

.frictionless-loading-block {
  background-color: white;
  padding: 2rem 3rem;
}

.cb-loading-indicator-wrap {
  padding: 1.25rem 0;
  text-align: center;
}

.cb-loading-indicator-wrap .stop-color-first {
  stop-color: rgb(20, 113, 218);
}

.cb-loading-indicator-wrap .stop-color-second,
.cb-loading-indicator-wrap .stop-color-third {
  stop-color: rgb(110, 168, 234);
}

.cb-loading-indicator {
  width: 2.813rem;
  height: 2.813rem;
  margin: 0 auto;
  animation: spin 0.8s infinite cubic-bezier(0.71, 0.12, 0.37, 0.95);
}

.cb-loading-indicator--lg {
  width: 5rem;
  height: 5rem;
}

.cb-loading-indicator + .headline {
  margin-top: 1.625rem;
}

.cb-loading-base {
  stroke-dasharray: 220, 360;
}

.cb-loading-circle {
  stroke-width: 14;
  fill: none;
  transform: rotate(135deg);
  transform-origin: center;
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}

@media (prefers-reduced-motion) {
  .cb-loading-indicator {
    animation: none;
  }
}
