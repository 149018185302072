.table-row {
  @apply flex;
}

.table-row-rule,
.table-row-rule-prominent {
  border-bottom: 1px solid #ccc;
}

.table-row-rule {
  padding: 1rem 0 0.563rem;
}

.table-row-card {
  padding-bottom: 0.5rem;
}

.table-key-bold {
  font-weight: bold;
}

.table-row-value {
  padding-left: 0.75rem;
  margin-left: auto;
  flex: 0.5;
  text-align: right;
}

.table-row-rule-prominent {
  padding: 0.75rem 0 0.938rem;
}

.table-row-prominent {
  padding: 0 0 0.5rem;
}

.table-row-indented {
  padding-left: 1.25rem;
}

.table-row-key-card {
  display: flex;
  margin-right: 0.5rem;
}

.table-row-key-prominent {
  flex: 0.75;
}

.table-row-key {
  flex: 0.5;
}

.table-row-value-prominent {
  text-align: right;
  padding-left: 0.75rem;
  flex: 0.25;
}

.table-row-key-card-icon {
  padding-right: 0.5rem;
}

.Visa {
  width: 37px;
  height: 24px;
}

@media screen and (max-width: 1023px) {
  .table-row-rule {
    padding: 1rem 0 0.688rem;
  }

  .table-row-indented {
    padding-left: 1.125rem;
  }
}

@media screen and (max-width: 767px) {
  .table-row-rule,
  .table-row-rule-prominent {
    padding: 0.75rem 0 0.438rem;
  }

  .table-row-card {
    padding-bottom: 0.25rem;
  }

  .table-row-value {
    padding-left: 0.5rem;
  }

  .table-row-prominent {
    padding: 0 0 0.25rem;
  }

  .table-row-indented {
    padding-left: 1.25rem;
  }
}
