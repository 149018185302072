.next-container {
  width: 11.25rem;
}

.complete-container {
  width: 13rem;
}

.stepper-container {
  padding-top: 1.5rem;
  position: relative;
  margin: 0 18.8%;
  display: flex;
  justify-content: center;
}

.sign-now-back {
  position: absolute;
  padding-top: 1.5rem;
  left: 0;
  top: 0;
}

.stepper-navigation-rule {
  border-bottom: 1px #ccc solid;
  padding-bottom: 2.1875rem;
}

@media screen and (max-width: 1023px) {
  .order-details-loading {
    padding-top: 2.25rem;
  }

  .stepper-container {
    padding-top: 1.5rem;
    position: relative;
    margin: 0 34px;
  }

  prism-stepper-navigation prism-button button .sc-prism-button-commercial {
    display: none;
  }

  .sign-now-back prism-button a .sc-prism-button-commercial {
    display: none;
  }
}

@media screen and (max-width: 767px) {
  .next-container {
    width: 100%;
  }

  .complete-container {
    width: 100%;
  }

  .account-reg-container .complete-container {
    width: 100%;
  }

  .order-details-loading {
    padding-top: 1.75rem;
  }

  .stepper-container {
    padding-top: 1.5rem;
    position: relative;
    margin: 0 24px;
  }
}

@media screen and (max-width: 376px) {
  .account-reg-container .complete-container {
    width: 100%;
  }
}
