.legal-agreement .sc-prism-checkbox-commercial-h {
  padding-bottom: 2.5rem;
}

.legal-agreement .legal-checkbox.sc-prism-legal-agreement-commercial {
  display: none;
  visibility: hidden;
}

.legal-agreement .sc-prism-legal-agreement-commercial-h {
  padding-bottom: 2.25rem;
}

.legal-agreement .text-area-main.sc-prism-legal-agreement-commercial {
  padding-bottom: 1.5rem;
  height: auto;
  max-height: 9.25rem;
}

prism-checkbox .hint-container prism-icon {
  display: none;
}
