@media screen and (min-width: 376px) {
  .invite-reg-inner {
    display: block;
  }

  .invite-reg-section1,
  .invite-reg-section2 {
    display: flex;
  }
}

@media screen and (max-width: 376px) {
  .hidden-mobile {
    display: none;
  }

  .visible-mobile {
    display: block;
  }

  .invite-reg-container {
    max-width: 100vw;
    margin: 1.125rem 0 0;
  }

  .invite-reg-inner {
    display: flex;
    flex-direction: column;
  }

  .text-field {
    margin: 0 0 1.25rem;
  }

  .invite-reg-required-text {
    margin-top: 2.5rem;
    margin-bottom: 1.5rem;
  }
}

@media screen and (min-width: 1024px) {
  .hidden-not-mobile {
    display: none;
  }

  .visible-not-mobile {
    display: block;
  }

  .invite-reg-container {
    max-width: 100vw;
    margin: 1.125rem 0 0;
  }

  .invite-reg-section1 prism-input:last-child,
  .invite-reg-section2 prism-input:last-child {
    margin-left: 2.25rem;
  }

  .text-field {
    margin: 0 0 1.25rem;
  }

  .invite-reg-required-text {
    margin-top: 3.25rem;
    margin-bottom: 1.75rem;
  }
}

@media screen and (min-width: 376px) and (max-width: 1023px) {
  .hidden-not-mobile {
    display: none;
  }

  .visible-not-mobile {
    display: block;
  }

  .invite-reg-container {
    max-width: 100vw;
    margin: 1.125rem 0 0;
  }

  .invite-reg-section1,
  .invite-reg-section2 {
    display: flex;
  }

  .invite-reg-section1 prism-input:last-child,
  .invite-reg-section2 prism-input:last-child {
    margin-left: 2.25rem;
  }

  .text-field {
    margin: 0 0 1rem;
  }

  .invite-reg-required-text {
    margin-top: 3.25rem;
    margin-bottom: 1.75rem;
  }
}
