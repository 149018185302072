.table-subtotal {
  display: flex;
  margin: 1.5rem 0 1.75rem;
}

.table-subtotal-right {
  margin-left: auto;
  padding-left: 0.75rem;
}

@media screen and (max-width: 767px) {
  .table-subtotal {
    margin: 1.25rem 0 1.5rem;
  }
}
