.self-reg-left {
  width: 50%;
}

.self-reg-right {
  width: 50%;
}

.self-reg-container prism-icon {
  display: none;
}

.self-reg-container .invalid-text {
  margin-top: 0.25rem;
  color: #d11314;
  font-family: Montserrat, Arial, 'Helvetica.Neue', Helvetica, sans-serif;
  font-size: 0.688rem;
  font-weight: 700;
  letter-spacing: 0.094rem;
  line-height: 1rem;
}

.setup-link-invite {
  margin: 0.3125rem 0;
}

@media screen and (max-width: 680px) {
  .hidden-PE-mobile {
    display: none;
  }

  .visible-PE-mobile {
    display: block;
  }

  .self-reg-field {
    width: 100%;
  }
}

@media screen and (min-width: 681px) {
  .hidden-PE {
    display: none;
  }

  .visible-PE {
    display: block;
    width: 50%;
  }

  .self-reg-field {
    width: 50%;
  }
}

@media screen and (max-width: 376px) {
  .self-reg-container {
    max-width: 100vw;
    display: flex;
    flex-direction: column;
    margin: 1.125rem 0 0;
  }

  .self-reg-details {
    margin-bottom: 4rem;
  }

  .self-reg-right {
    margin: 1.25rem 0 0;
    padding-left: 0;
  }

  .self-reg-form {
    display: flex;
    flex-direction: column;
  }

  .self-reg-text {
    display: inline-block;
    width: 20.75rem;
    max-width: 100%;
  }

  .text-field {
    margin: 0 0 1.25rem;
  }

  .self-reg-checkbox {
    margin: 1.25rem 0 2.5rem;
  }

  .self-reg-required-text {
    margin-top: 2.5rem;
    margin-bottom: 1.5rem;
  }
}

@media screen and (min-width: 1024px) {
  .self-reg-container {
    max-width: 100%;
    display: flex;
    flex-direction: column;
    margin: 1.125rem 0 0;
  }

  .self-reg-details {
    display: flex;
    flex-direction: row;
    justify-content: left;
    margin-bottom: 3.25rem;
  }

  .self-reg-form {
    display: flex;
    flex-direction: row;
  }

  .self-reg-text {
    display: inline-block;
    width: 20.75rem;
    max-width: 100%;
  }

  .text-field {
    margin: 0 0 1.25rem;
  }

  .self-reg-checkbox {
    margin: 0.75rem 0 1rem;
  }

  .self-reg-required-text {
    margin-top: 3.25rem;
    margin-bottom: 1.75rem;
  }
}

@media screen and (min-width: 377px) and (max-width: 1023px) {
  .self-reg-container {
    max-width: 100vw;
    display: flex;
    flex-direction: column;
    margin: 1.125rem 0 0;
  }

  .self-reg-details {
    display: flex;
    flex-direction: row;
    justify-content: left;
    margin-bottom: 2.5rem;
  }

  .self-reg-right {
    padding-left: 2rem;
  }

  .self-reg-form {
    display: flex;
    flex-direction: row;
  }

  .self-reg-text {
    display: inline-block;
    width: 20.75rem;
    max-width: 100%;
  }

  .text-field {
    margin: 0 0 1rem;
  }

  .self-reg-checkbox {
    margin: 0.75rem 0 1rem;
  }

  .self-reg-required-text {
    margin-top: 3.25rem;
    margin-bottom: 1.75rem;
  }
}
