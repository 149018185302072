.container-component {
  margin: 0 18.8%;
}

@media screen and (max-width: 1023px) {
  .container-component {
    margin: 0 34px;
  }
}

@media screen and (max-width: 767px) {
  .container-component {
    margin: 0 24px;
  }
}
