.table-row-rule:last-child {
  border: none;
}

.table:last-child {
  margin-bottom: 1.75rem;
}

.phone-transfer-terms-and-condition {
  border-top: 0.0625rem #ccc solid;
  margin-bottom: 2.25rem;
}
