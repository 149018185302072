.table-header {
  @apply block box-border;
  margin: 1.5rem 0 1.25rem;
  padding-bottom: 0.5rem;
  border-bottom: 0.25rem solid #ccc;
}

@media screen and (max-width: 767px) {
  .table-header {
    margin: 1.25rem 0 1rem;
  }
}
