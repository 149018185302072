.table-section-header {
  padding: 1rem 0;
}

.table-section-header-description {
  margin-top: 0.25rem;
}

@media screen and (max-width: 767px) {
  .table-section-header {
    padding: 0.75rem 0;
  }
}
