.sign-pad {
  background-color: #f7f7f7;
  margin-left: 3rem;
  margin-right: 3rem;
  padding: 0.625rem 0.625rem 0.625rem 0.625rem;
  width: 90%;
  margin-top: 1.25rem;
}

.invalid-sign-pad-error {
  background-color: lightgrey;
  margin-left: 3rem;
  margin-right: 3rem;
  padding: 0.625rem 0.625rem 0.625rem 0.625rem;
  width: 90%;
  margin-top: 1.25rem;
  border-width: 0.094rem;
  border-color: #d11314;
}

.sign-container {
  margin-bottom: 1.25rem;
  margin-top: 1.25rem;
}

.sign-container-disable {
  margin-bottom: 1.25rem;
  margin-top: 1.25rem;
  pointer-events: none;
}

.sign-heading {
  text-align: center;
}

.canvas-disable {
  pointer-events: none;
}

.sign-accept-button {
  width: 9.3125rem;
  margin: 2.5rem auto 0.2rem;
}

.sign-pad-clear {
  text-align: end;
}

.invalid-sign-accept-button-error {
  text-align: center;
  color: #d11314;
}

.sign-accept-button prism-button .btn.fill.sc-prism-button-commercial {
  background-color: #000;
  border: 0.1875rem solid #000;
  text-align: center;
}

.sign-accept-button prism-button .btn.fill.sc-prism-button-commercial:not(.disabled):focus,
.sign-accept-button prism-button .btn.fill.sc-prism-button-commercial:not(.disabled):hover,
.sign-accept-button prism-button .btn.fill.sc-prism-button-commercial:not(.disabled):active {
  background-color: #35353b;
  border: 0.1875rem solid #35353b;
  text-align: center;
}

.sign-accept-button .btn.fill.sc-prism-button-commercial:not(.disabled):focus .text.sc-prism-button-commercial {
  color: #fff;
}

.sign-accepted-button {
  text-align: center;
  margin-top: 2.5rem;
  display: flex;
  justify-content: center;
}

.sign-accepted-text {
  padding-left: 0.625rem;
}

@media screen and (max-width: 767px) {
  .sign-pad {
    margin-left: 1px;
    margin-right: 1px;
    width: 100%;
  }

  .invalid-sign-pad-error {
    margin-left: 1px;
    margin-right: 1px;
    width: 100%;
  }
}
