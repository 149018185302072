.order-details-next-button {
  width: 11.25rem;
}

.order-details-action {
  color: #0d62ff;
}

.order-details-loading {
  padding-top: 2.75rem;
}

.order-details-stepper {
  padding-top: 1.5rem;
}

.terms-legal {
  padding-bottom: 1.5rem;
}

.terms-legal-main {
  padding-top: 3rem;
  padding-bottom: 2.5rem;
}

@media screen and (max-width: 1023px) {
  .order-details-loading {
    padding-top: 2.25rem;
  }
}

@media screen and (max-width: 767px) {
  .order-details-next-button {
    width: 9.5rem;
  }

  .order-details-loading {
    padding-top: 1.75rem;
  }
}

input[type='checkbox']:required {
  pointer-events: none;
}
