/** Tailwind Components & Utilities **/
.mt-4 {
  margin-top: 1rem;
}
.block {
  display: block;
}
.inline {
  display: inline;
}
.flex {
  display: flex;
}
.table {
  display: table;
}
.table-row {
  display: table-row;
}
.contents {
  display: contents;
}
.w-1\/3 {
  width: 33.333333%;
}
.transform {
  -webkit-transform: var(--tw-transform);
          transform: var(--tw-transform);
}
.items-start {
  align-items: flex-start;
}
.items-center {
  align-items: center;
}
.space-x-1 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(0.25rem * var(--tw-space-x-reverse));
  margin-left: calc(0.25rem * calc(1 - var(--tw-space-x-reverse)));
}
.border {
  border-width: 1px;
}
.font-bold {
  font-weight: 700;
}
.uppercase {
  text-transform: uppercase;
}
.lowercase {
  text-transform: lowercase;
}
.filter {
  -webkit-filter: var(--tw-filter);
          filter: var(--tw-filter);
}
.transition {
  transition-property: background-color, border-color, color, fill, stroke, opacity, box-shadow, -webkit-transform, -webkit-filter, -webkit-backdrop-filter;
  transition-property: background-color, border-color, color, fill, stroke, opacity, box-shadow, transform, filter, backdrop-filter;
  transition-property: background-color, border-color, color, fill, stroke, opacity, box-shadow, transform, filter, backdrop-filter, -webkit-transform, -webkit-filter, -webkit-backdrop-filter;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 150ms;
}
.container {
  width: 100%;
}
@media (min-width: 375px) {
  .container {
    max-width: 375px;
  }
}
@media (min-width: 768px) {
  .container {
    max-width: 768px;
  }
}
@media (min-width: 1024px) {
  .container {
    max-width: 1024px;
  }
}
@media (min-width: 1280px) {
  .container {
    max-width: 1280px;
  }
}
@media (min-width: 1568px) {
  .container {
    max-width: 1568px;
  }
}
body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

/* App Specific Styles */
.w-28 {
  width: 28px;
}
.sc-prism-layout-h {
  height: inherit;
}

.frictionless-loading-holder {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.75);
  z-index: 10000;
  height: 100vh;
}

.frictionless-loading-block {
  background-color: white;
  padding: 2rem 3rem;
}

.cb-loading-indicator-wrap {
  padding: 1.25rem 0;
  text-align: center;
}

.cb-loading-indicator-wrap .stop-color-first {
  stop-color: rgb(20, 113, 218);
}

.cb-loading-indicator-wrap .stop-color-second,
.cb-loading-indicator-wrap .stop-color-third {
  stop-color: rgb(110, 168, 234);
}

.cb-loading-indicator {
  width: 2.813rem;
  height: 2.813rem;
  margin: 0 auto;
  -webkit-animation: spin 0.8s infinite cubic-bezier(0.71, 0.12, 0.37, 0.95);
          animation: spin 0.8s infinite cubic-bezier(0.71, 0.12, 0.37, 0.95);
}

.cb-loading-indicator--lg {
  width: 5rem;
  height: 5rem;
}

.cb-loading-indicator + .headline {
  margin-top: 1.625rem;
}

.cb-loading-base {
  stroke-dasharray: 220, 360;
}

.cb-loading-circle {
  stroke-width: 14;
  fill: none;
  -webkit-transform: rotate(135deg);
          transform: rotate(135deg);
  -webkit-transform-origin: center;
          transform-origin: center;
}

@-webkit-keyframes spin {
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes spin {
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@media (prefers-reduced-motion) {
  .cb-loading-indicator {
    -webkit-animation: none;
            animation: none;
  }
}

#main {
  max-width: 100vw;
}

@media screen and (max-width: 376px) {
  .account-reg-container {
    margin: 0 1.5rem 6.25rem;
  }

  .complete-container {
    width: 100%;
  }
}

@media screen and (min-width: 1024px) {
  .account-reg-container {
    margin: 0 18% 9rem;
  }
}

@media screen and (min-width: 376px) and (max-width: 1023px) {
  .account-reg-container {
    margin: 0 2.125rem 9rem;
  }
}

.order-details-next-button {
  width: 11.25rem;
}

.order-details-action {
  color: #0d62ff;
}

.order-details-loading {
  padding-top: 2.75rem;
}

.order-details-stepper {
  padding-top: 1.5rem;
}

@media screen and (max-width: 1023px) {
  .order-details-loading {
    padding-top: 2.25rem;
  }
}

@media screen and (max-width: 767px) {
  .order-details-loading {
    padding-top: 1.75rem;
  }

  .order-details-next-button {
    width: 9.5rem;
  }
}

@media screen and (min-width: 376px) {
  .invite-reg-inner {
    display: block;
  }

  .invite-reg-section1,
  .invite-reg-section2 {
    display: flex;
  }
}

@media screen and (max-width: 376px) {
  .hidden-mobile {
    display: none;
  }

  .visible-mobile {
    display: block;
  }

  .invite-reg-container {
    max-width: 100vw;
    margin: 1.125rem 0 0;
  }

  .invite-reg-inner {
    display: flex;
    flex-direction: column;
  }

  .text-field {
    margin: 0 0 1.25rem;
  }

  .invite-reg-required-text {
    margin-top: 2.5rem;
    margin-bottom: 1.5rem;
  }
}

@media screen and (min-width: 1024px) {
  .hidden-not-mobile {
    display: none;
  }

  .visible-not-mobile {
    display: block;
  }

  .invite-reg-container {
    max-width: 100vw;
    margin: 1.125rem 0 0;
  }

  .invite-reg-section1 prism-input:last-child,
  .invite-reg-section2 prism-input:last-child {
    margin-left: 2.25rem;
  }

  .text-field {
    margin: 0 0 1.25rem;
  }

  .invite-reg-required-text {
    margin-top: 3.25rem;
    margin-bottom: 1.75rem;
  }
}

@media screen and (min-width: 376px) and (max-width: 1023px) {
  .hidden-not-mobile {
    display: none;
  }

  .visible-not-mobile {
    display: block;
  }

  .invite-reg-container {
    max-width: 100vw;
    margin: 1.125rem 0 0;
  }

  .invite-reg-section1,
  .invite-reg-section2 {
    display: flex;
  }

  .invite-reg-section1 prism-input:last-child,
  .invite-reg-section2 prism-input:last-child {
    margin-left: 2.25rem;
  }

  .text-field {
    margin: 0 0 1rem;
  }

  .invite-reg-required-text {
    margin-top: 3.25rem;
    margin-bottom: 1.75rem;
  }
}

:root {
  --checkbox-error-color: #d11314;
  --checkbox-size: 1.75rem;
}

.prism-checkbox {
  position: relative;
}

.legal-agreement .prism-checkbox {
  padding-bottom: 2.5rem;
}

.prism-checkbox:hover .label label {
  cursor: pointer;
}

.checkmark {
  height: 13.07px;
  width: 15.87px;
}

.checkmark .checked {
  fill: #000;
}

.label:hover:not(.checked) label .checkmark {
  fill: #c0c0c0;
  opacity: 1;
}

.label:hover label .checkmark .checked {
  fill: #000;
  opacity: 1;
}

.display-none {
  opacity: 0;
}

.custom-checkbox {
  width: 1.75rem;
  width: 1.75rem;
  width: var(--checkbox-size);
  height: 1.75rem;
  height: 1.75rem;
  height: var(--checkbox-size);
  opacity: 0;
}

.checkbox-border {
  position: absolute;
  width: 1.75rem;
  width: 1.75rem;
  width: var(--checkbox-size);
  height: 1.75rem;
  height: 1.75rem;
  height: var(--checkbox-size);
  border: 2px solid #414141;
  display: flex;
  align-items: center;
  justify-content: center;
}

.checkbox-error {
  border: 2px solid #d11314;
  border: 2px solid #d11314;
  border: 2px solid var(--checkbox-error-color);
}

.checkbox-focus {
  border: 2px solid #21a2fd;
}

.invalid-message {
  color: #d11314;
  color: #d11314;
  color: var(--checkbox-error-color);
  font-family: Montserrat, Arial, 'Helvetica.Neue', Helvetica, sans-serif;
  font-size: 0.688rem;
  letter-spacing: 0.094rem;
  line-height: 1rem;
}

.checkbox-label-text {
  color: rgba(0, 0, 0, 1);
  font-family: 'Lato', Arial, 'Helvetica Neue', Helvetica, sans-serif;
  font-size: 1rem;
  font-weight: 400;
  letter-spacing: 0.031rem;
  line-height: 1.5rem;
  margin-left: 1.25rem;
}

.invalid {
  color: #d11314;
  color: #d11314;
  color: var(--checkbox-error-color);
}

.disabled {
  background-color: #e5e5e5;
  border: none;
}

.self-reg-left {
  width: 50%;
}

.self-reg-right {
  width: 50%;
}

.self-reg-container prism-icon {
  display: none;
}

.self-reg-container .invalid-text {
  margin-top: 0.25rem;
  color: #d11314;
  font-family: Montserrat, Arial, 'Helvetica.Neue', Helvetica, sans-serif;
  font-size: 0.688rem;
  font-weight: 700;
  letter-spacing: 0.094rem;
  line-height: 1rem;
}

.setup-link-invite {
  margin: 0.3125rem 0;
}

@media screen and (max-width: 680px) {
  .hidden-PE-mobile {
    display: none;
  }

  .visible-PE-mobile {
    display: block;
  }

  .self-reg-field {
    width: 100%;
  }
}

@media screen and (min-width: 681px) {
  .hidden-PE {
    display: none;
  }

  .visible-PE {
    display: block;
    width: 50%;
  }

  .self-reg-field {
    width: 50%;
  }
}

@media screen and (max-width: 376px) {
  .self-reg-container {
    max-width: 100vw;
    display: flex;
    flex-direction: column;
    margin: 1.125rem 0 0;
  }

  .self-reg-details {
    margin-bottom: 4rem;
  }

  .self-reg-right {
    margin: 1.25rem 0 0;
    padding-left: 0;
  }

  .self-reg-form {
    display: flex;
    flex-direction: column;
  }

  .self-reg-text {
    display: inline-block;
    width: 20.75rem;
    max-width: 100%;
  }

  .text-field {
    margin: 0 0 1.25rem;
  }

  .self-reg-checkbox {
    margin: 1.25rem 0 2.5rem;
  }

  .self-reg-required-text {
    margin-top: 2.5rem;
    margin-bottom: 1.5rem;
  }
}

@media screen and (min-width: 1024px) {
  .self-reg-container {
    max-width: 100%;
    display: flex;
    flex-direction: column;
    margin: 1.125rem 0 0;
  }

  .self-reg-details {
    display: flex;
    flex-direction: row;
    justify-content: left;
    margin-bottom: 3.25rem;
  }

  .self-reg-form {
    display: flex;
    flex-direction: row;
  }

  .self-reg-text {
    display: inline-block;
    width: 20.75rem;
    max-width: 100%;
  }

  .text-field {
    margin: 0 0 1.25rem;
  }

  .self-reg-checkbox {
    margin: 0.75rem 0 1rem;
  }

  .self-reg-required-text {
    margin-top: 3.25rem;
    margin-bottom: 1.75rem;
  }
}

@media screen and (min-width: 377px) and (max-width: 1023px) {
  .self-reg-container {
    max-width: 100vw;
    display: flex;
    flex-direction: column;
    margin: 1.125rem 0 0;
  }

  .self-reg-details {
    display: flex;
    flex-direction: row;
    justify-content: left;
    margin-bottom: 2.5rem;
  }

  .self-reg-right {
    padding-left: 2rem;
  }

  .self-reg-form {
    display: flex;
    flex-direction: row;
  }

  .self-reg-text {
    display: inline-block;
    width: 20.75rem;
    max-width: 100%;
  }

  .text-field {
    margin: 0 0 1rem;
  }

  .self-reg-checkbox {
    margin: 0.75rem 0 1rem;
  }

  .self-reg-required-text {
    margin-top: 3.25rem;
    margin-bottom: 1.75rem;
  }
}

@media screen and (max-width: 680px) {
  .password-essentials-container {
    margin-left: 0;
  }
}

@media screen and (min-width: 681px) and (max-width: 1023px) {
  .password-essentials-container {
    margin-left: 2rem;
  }
}

@media screen and (max-width: 376) {
  .password-essentials-header {
    display: block;
    font-size: 0.875rem;
    font-weight: bold;
    height: 2rem;
    margin-bottom: 1rem;
  }
}

@media screen and (min-width: 1024px) {
  .password-essentials-container {
    margin-left: 2.3125rem;
  }

  .password-essentials-header {
    display: block;
    font-size: 0.875rem;
    font-weight: bold;
    height: 2rem;
    margin-bottom: 1rem;
  }
}

@media screen and (min-width: 376px) and (max-width: 1023px) {
  .password-essentials-header {
    display: block;
    font-size: 0.75rem;
    font-weight: bold;
    height: 2rem;
    margin-bottom: 1rem;
  }
}

.validation-criteria {
  display: flex;
  margin-bottom: 0.875rem;
}

.criteria-img-container {
  margin-right: 1.0625rem;
}

.criteria-image {
  width: 1.125rem;
  height: 1.125rem;
}

.modal {
  position: fixed;
  z-index: 1000;
  left: 0;
  top: 0;
  height: 100%;
  overflow: auto;
  background-color: rgba(0, 0, 0, 0.4);
}

.modal-overlay {
  position: fixed;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
}

.modal-content {
  position: relative;
  background-color: #fefefe;
  margin: 15% auto;
  border: 1px solid #888;
  width: 80%;
  text-align: center;
}

.modal-header {
  color: rgb(0, 0, 0);
  font-size: 32px;
  font-weight: bold;
  text-align: center;
  line-height: 40px;
  margin: 68px 0 20px;
}

.modal-body {
  width: 87.4%;
  margin: 0 auto 68px;
}

.close {
  position: relative;
  width: 1.5rem;
  height: 1.5rem;
  top: 20px;
  right: 20px;
  color: rgb(170, 170, 170);
  float: right;
  font-size: 28px;
  font-weight: bold;
}

.close:hover,
.close:focus {
  color: black;
  text-decoration: none;
  cursor: pointer;
}

.setup-container {
  margin: 0 0 2.5rem;
}

.setup-text {
  margin-top: 3.25rem;
}

.setup-link {
  display: contents;
  color: #0d62ff;
  text-decoration: none;
  text-align: left;
}

.invite-reg-commercial-link-container {
  display: flex;
  justify-content: flex-start;
}

.invite-reg-commercial-link-container prism-button prism-text {
  text-transform: none;
  font-weight: bold;
}

.setup-img-container {
  margin-left: -0.625rem;
}

.arrow-left-image {
  width: 1.1875rem;
  height: 0.875rem;
  margin-right: 0.625rem;
}

@media screen and (max-width: 1023px) {
  .setup-container {
    margin: 0 0 2.5rem;
  }

  .setup-header-container {
    padding-bottom: 2.1875rem;
    border-bottom: 0.125rem solid gray;
  }

  .setup-header {
    display: block;
    font-size: 2rem;
    letter-spacing: 0;
  }

  .setup-link {
    display: contents;
    color: #0d62ff;
    text-decoration: none;
  }
}

@media screen and (max-width: 767px) {
  .setup-container {
    margin: 0 0 1.25rem;
  }

  .setup-text {
    margin-top: 2.5625rem;
  }

  .setup-link {
    display: contents;
    color: #0d62ff;
    text-decoration: none;
  }
}

@media screen and (max-width: 446px) {
  .invite-reg-commercial-link-container {
    display: flex;
    justify-content: flex-start;
    height: 2.1875rem;
  }
}

.next-container {
  width: 11.25rem;
}

.complete-container {
  width: 13rem;
}

.stepper-container {
  padding-top: 1.5rem;
  position: relative;
  margin: 0 18.8%;
  display: flex;
  justify-content: center;
}

.sign-now-back {
  position: absolute;
  padding-top: 1.5rem;
  left: 0;
  top: 0;
}

.stepper-navigation-rule {
  border-bottom: 1px #ccc solid;
  padding-bottom: 2.1875rem;
}

@media screen and (max-width: 1023px) {
  .order-details-loading {
    padding-top: 2.25rem;
  }

  .stepper-container {
    padding-top: 1.5rem;
    position: relative;
    margin: 0 34px;
  }

  prism-stepper-navigation prism-button button .sc-prism-button-commercial {
    display: none;
  }

  .sign-now-back prism-button a .sc-prism-button-commercial {
    display: none;
  }
}

@media screen and (max-width: 767px) {
  .next-container {
    width: 100%;
  }

  .complete-container {
    width: 100%;
  }

  .account-reg-container .complete-container {
    width: 100%;
  }

  .order-details-loading {
    padding-top: 1.75rem;
  }

  .stepper-container {
    padding-top: 1.5rem;
    position: relative;
    margin: 0 24px;
  }
}

@media screen and (max-width: 376px) {
  .account-reg-container .complete-container {
    width: 100%;
  }
}

.container-component {
  margin: 0 18.8%;
}

@media screen and (max-width: 1023px) {
  .container-component {
    margin: 0 34px;
  }
}

@media screen and (max-width: 767px) {
  .container-component {
    margin: 0 24px;
  }
}

.account-management-section {
  width: 34.273%;
  padding-right: 1.688rem;
}

.account-management-section:last-child {
  width: 31.452%;
  padding-right: 0;
}

.auto-account-management-section {
  display: flex;
  flex-wrap: wrap;
  flex: 1 1;
  flex-direction: row;
}

.auto-icon-section {
  width: 5%;
}

.auto-account-management-container {
  padding-bottom: 1.5rem;
  padding-top: 1rem;
}

.autopay-account-address-container {
  display: flex;
  flex-wrap: wrap;
  flex: 1 1;
  flex-direction: row;
  padding-top: 2rem;
  padding-left: 5%;
}

.title-container {
  margin-left: 5%;
  margin-top: 2rem;
}

.title-description {
  margin-top: 0.3rem;
  margin-bottom: 1.5rem;
}

.auto-description-section {
  padding-left: 5%;
}

.heading-text {
  margin-top: 1.5rem;
  padding-left: 5%;
  padding-bottom: 1rem;
}

.additional-padding {
  padding-bottom: 8px;
}

.quetion-section {
  display: flex;
  flex-direction: row;
  margin-top: 1.5rem;
  margin-bottom: 2.5rem;
  padding-left: 5%;
}

.sales-section {
  padding-left: 0.5rem;
}

.account-address-section {
  padding-right: 4rem;
}

.auto-pay-account-address-section {
  padding-bottom: 2rem;
  padding-right: 6rem;
  width: 33%;
}

.account-management-container {
  display: flex;
  flex-wrap: wrap;
  flex: 1 1;
  flex-direction: row;
  padding-bottom: 2.5rem;
}

.confirm-button {
  width: 16.3125rem;
  padding-bottom: 2.5rem;
}

.dashboard-button prism-button {
  width: 16.4375rem;
  padding-bottom: 2.5rem;
  padding-top: 2.5rem;
}

.dashboard-button prism-button a.btn.fill.sc-prism-button-commercial {
  background-color: #000;
  border: 0.1875rem solid #000;
}

.dashboard-button prism-button a.btn.fill.sc-prism-button-commercial:not(.disabled):focus,
.dashboard-button prism-button a.btn.fill.sc-prism-button-commercial:not(.disabled):hover,
.dashboard-button prism-button a.btn.fill.sc-prism-button-commercial:not(.disabled):active {
  background-color: #35353b;
  border: 0.1875rem solid #35353b;
}

.dashboard-button a.btn.fill.sc-prism-button-commercial:not(.disabled):focus .text.sc-prism-button-commercial {
  color: #fff;
}

.confirmation-rule {
  border-bottom: 1px #ccc solid;
}

.autopayConfirmationRule {
  border-bottom: 1px #ccc solid;
  margin-left: 5%;
}

.confirmation-info {
  padding: 2.5rem 0;
}

.order-button prism-button {
  width: 16.4375rem;
  padding-bottom: 2.5rem;
  padding-left: 5%;
}

.order-button prism-button a.btn.fill.sc-prism-button-commercial {
  background-color: #000;
  border: 0.1875rem solid #000;
}

.order-button prism-button a.btn.fill.sc-prism-button-commercial:not(.disabled):focus,
.order-button prism-button a.btn.fill.sc-prism-button-commercial:not(.disabled):hover,
.order-button prism-button a.btn.fill.sc-prism-button-commercial:not(.disabled):active {
  background-color: #35353b;
  border: 0.1875rem solid #35353b;
}

.order-button a.btn.fill.sc-prism-button-commercial:not(.disabled):focus .text.sc-prism-button-commercial {
  color: #fff;
}

.account-address-container {
  display: flex;
  flex: 1 1;
  flex-direction: row;
  padding-top: 2rem;
  padding-bottom: 2rem;
}

.confirmation-navigation-container-header {
  text-align: center;
  padding-top: 2rem;
}

.confirmation-navigation-container {
  display: flex;
  flex-wrap: wrap;
  margin: 3.125rem 2.5rem;
  flex: 1 1;
  flex-direction: row;
  padding-bottom: 2.5rem;
}

.confirmation-navigation-section {
  width: 34.08%;
  padding-right: 1.625rem;
  display: block;
}

.confirmation-navigation-section:last-child {
  width: 31.84%;
  padding-right: 0;
  display: block;
}

.navigation-space1 {
  padding-bottom: 1.25rem;
}

.navigation-space2 {
  padding-bottom: 0.5rem;
}

.navigation-space3 {
  padding-bottom: 0.75rem;
}

.navigation-link {
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
}

.navigation-link prism-button a .text.sc-prism-button-commercial.sc-prism-button-commercial-s.block {
  margin: 0;
}

@media screen and (max-width: 767px) {
  .auto-icon-section {
    width: 11%;
  }

  .order-button prism-button,
  .quetion-section,
  .heading-text,
  .auto-description-section {
    padding-left: 11%;
  }

  .autopayConfirmationRule,
  .title-container {
    margin-left: 11%;
  }

  .account-address-container {
    display: block;
    flex-wrap: wrap;
    flex: 1 1;
    flex-direction: row;
    padding-top: 1.875rem;
  }

  .autopay-account-address-container {
    display: block;
    flex-wrap: wrap;
    flex: 1 1;
    flex-direction: row;
    padding-top: 2rem;
    padding-left: 11%;
  }

  .auto-pay-account-address-section {
    padding-right: 0;
    width: 100%;
  }

  .account-management-container {
    display: block;
    flex-wrap: wrap;
    flex: 1 1;
    flex-direction: row;
  }

  .confirmation-navigation-container {
    display: block;
    flex-wrap: wrap;
    margin: 0 1.5rem;
    flex: 1 1;
    flex-direction: row;
    padding: 0.75rem 0;
  }

  .confirmation-navigation-section,
  .confirmation-navigation-section:last-child {
    width: 100%;
    padding: 1.25rem 0;
    display: block;
  }

  .account-management-section {
    padding-right: 0.313rem;
    width: 100%;
    padding-bottom: 0.938rem;
  }

  .confirm-button {
    width: 100%;
  }

  .dashboard-button prism-button {
    width: 100%;
  }

  .account-management-section:last-child {
    padding-right: 0.313rem;
    width: 100%;
    padding-bottom: 0.938rem;
  }

  .account-address-section {
    display: block;
    padding-right: 0.313rem;
    padding-bottom: 1.5rem;
    width: 100%;
  }
}

@media screen and (min-width: 768px) and (max-width: 1023px) {
  .confirmation-navigation-container {
    display: block;
    flex-wrap: wrap;
    margin: 0 2.125rem;
    flex: 1 1;
    flex-direction: row;
    padding: 1.25rem 0;
  }

  .confirmation-navigation-section,
  .confirmation-navigation-section:last-child {
    width: 100%;
    display: flex;
    padding: 1.25rem 0;
  }

  .navigation-subsection {
    width: 50%;
  }

  .section1 {
    padding-right: 1.813rem;
  }

  .section2 {
    padding-left: 1.813rem;
  }
}

.legal-agreement-header {
  flex-direction: row;
  margin-bottom: 0.5rem;
  display: flex;
}

.download-print {
  display: flex;
  margin-left: auto;
  color: #0d62ff;
}

.legal-checkbox-main {
  margin-top: 2.25rem;
}

s .icon2 {
  padding-left: 0.625rem;
}

.text-area-main {
  overflow-y: scroll;
  border: 1px solid rgb(204 204 204);
  padding-left: 1.5rem;
  padding-right: 2.688rem;
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
  height: auto;
  max-height: 9.25rem;
}

.legal-agreement {
  flex-direction: column;
  padding-bottom: 2.25rem;
}

.legal-print-icon {
  margin-left: 1.25rem;
}

@media screen and (min-width: 681px) {
  .legal-agreement {
    flex-direction: row;
  }
}

@media screen and (max-width: 680px) {
  .title {
    margin-bottom: 0.125rem;
  }

  .legal-agreement-header {
    flex-direction: column;
  }

  .legal-agreement {
    margin: 0;
    padding-bottom: 2rem;
  }

  .download-print {
    margin: 0.4rem 0 0.4rem 0;
  }

  .text-area-main {
    padding-right: 0.375rem;
  }

  .legal-checkbox {
    margin-top: 0.438rem;
  }
}

.legal-agreement .sc-prism-checkbox-commercial-h {
  padding-bottom: 2.5rem;
}

.legal-agreement .legal-checkbox.sc-prism-legal-agreement-commercial {
  display: none;
  visibility: hidden;
}

.legal-agreement .sc-prism-legal-agreement-commercial-h {
  padding-bottom: 2.25rem;
}

.legal-agreement .text-area-main.sc-prism-legal-agreement-commercial {
  padding-bottom: 1.5rem;
  height: auto;
  max-height: 9.25rem;
}

prism-checkbox .hint-container prism-icon {
  display: none;
}

.sign-pad {
  background-color: #f7f7f7;
  margin-left: 3rem;
  margin-right: 3rem;
  padding: 0.625rem 0.625rem 0.625rem 0.625rem;
  width: 90%;
  margin-top: 1.25rem;
}

.invalid-sign-pad-error {
  background-color: lightgrey;
  margin-left: 3rem;
  margin-right: 3rem;
  padding: 0.625rem 0.625rem 0.625rem 0.625rem;
  width: 90%;
  margin-top: 1.25rem;
  border-width: 0.094rem;
  border-color: #d11314;
}

.sign-container {
  margin-bottom: 1.25rem;
  margin-top: 1.25rem;
}

.sign-container-disable {
  margin-bottom: 1.25rem;
  margin-top: 1.25rem;
  pointer-events: none;
}

.sign-heading {
  text-align: center;
}

.canvas-disable {
  pointer-events: none;
}

.sign-accept-button {
  width: 9.3125rem;
  margin: 2.5rem auto 0.2rem;
}

[dir="ltr"] .sign-pad-clear {
  text-align: right;
}

.sign-pad-clear:dir(ltr) {
  text-align: right;
}

[dir="rtl"] .sign-pad-clear {
  text-align: left;
}

.sign-pad-clear:dir(rtl) {
  text-align: left;
}

.sign-pad-clear {
  text-align: end;
}

.invalid-sign-accept-button-error {
  text-align: center;
  color: #d11314;
}

.sign-accept-button prism-button .btn.fill.sc-prism-button-commercial {
  background-color: #000;
  border: 0.1875rem solid #000;
  text-align: center;
}

.sign-accept-button prism-button .btn.fill.sc-prism-button-commercial:not(.disabled):focus,
.sign-accept-button prism-button .btn.fill.sc-prism-button-commercial:not(.disabled):hover,
.sign-accept-button prism-button .btn.fill.sc-prism-button-commercial:not(.disabled):active {
  background-color: #35353b;
  border: 0.1875rem solid #35353b;
  text-align: center;
}

.sign-accept-button .btn.fill.sc-prism-button-commercial:not(.disabled):focus .text.sc-prism-button-commercial {
  color: #fff;
}

.sign-accepted-button {
  text-align: center;
  margin-top: 2.5rem;
  display: flex;
  justify-content: center;
}

.sign-accepted-text {
  padding-left: 0.625rem;
}

@media screen and (max-width: 767px) {
  .sign-pad {
    margin-left: 1px;
    margin-right: 1px;
    width: 100%;
  }

  .invalid-sign-pad-error {
    margin-left: 1px;
    margin-right: 1px;
    width: 100%;
  }
}

.uploaded-doc-container {
  position: relative;
}

.uploaded-doc-description {
  margin-bottom: 0.75rem;
}

.uploaded-doc-header {
  font-size: 1.25rem;
  font-weight: 700;
}

.uploaded-doc-header-desc {
  font-size: 0.875rem;
}

.uploaded-doc-icon {
  display: flex;
  padding: 0.125rem 1rem 0 1.26rem;
}

.upload-doc-file-name {
  color: #000;
  font-size: 0.875rem;
}

.upload-doc-file-size {
  color: #757575;
  font-size: 0.875rem;
}

.uploaded-doc-info {
  display: flex;
  align-items: flex-start;
  background-color: #f7f7f7;
  width: 100%;
  padding: 0.75rem 0 0.75rem;
  min-height: 3rem;
  border: 1px solid #ccc;
  margin-bottom: 2.75rem;
}

.uploaded-doc-button {
  background-color: #f7f7f7;
  color: #0d62ff;
  font-weight: 700;
  font-size: 16px;
  height: 1.5rem;
  margin-left: auto;
  padding-left: 1rem;
  padding-right: 1rem;
  padding-bottom: 0.875rem;
}

.error-block {
  height: 100%;
  display: flex;
  flex-direction: column;
}

.error-text-centered {
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex-grow: 1;
  text-align: center;
  width: 100vw;
  height: 100vh;
}

.error-text {
  width: 590px;
}

.error-text-heading {
  display: block;
  margin-bottom: 24px;
}

.screen-reader {
  position: absolute !important;
  height: 1px;
  overflow: hidden;
  clip: rect(1px, 1px, 1px, 1px);
}

@media screen and (max-width: 1023px) {
  .error-text {
    width: 540px;
  }

  .error-text-heading {
    margin-bottom: 20px;
  }
}

@media screen and (max-width: 767px) {
  .error-text {
    width: inherit;
    padding: 0 24px;
  }

  .error-text-heading {
    margin-bottom: 16px;
  }
}

.table-header {
  box-sizing: border-box;
  display: block;
  margin: 1.5rem 0 1.25rem;
  padding-bottom: 0.5rem;
  border-bottom: 0.25rem solid #ccc;
}

@media screen and (max-width: 767px) {
  .table-header {
    margin: 1.25rem 0 1rem;
  }
}

.table {
  width: 100%;
  text-align: left;
}

.table-body {
  display: block;
  margin: 0 4.016rem;
}

@media screen and (max-width: 767px) {
  .table-body {
    margin: 0;
  }
}

.table-row {
  display: flex;
}

.table-row-rule,
.table-row-rule-prominent {
  border-bottom: 1px solid #ccc;
}

.table-row-rule {
  padding: 1rem 0 0.563rem;
}

.table-row-card {
  padding-bottom: 0.5rem;
}

.table-key-bold {
  font-weight: bold;
}

.table-row-value {
  padding-left: 0.75rem;
  margin-left: auto;
  flex: 0.5 1;
  text-align: right;
}

.table-row-rule-prominent {
  padding: 0.75rem 0 0.938rem;
}

.table-row-prominent {
  padding: 0 0 0.5rem;
}

.table-row-indented {
  padding-left: 1.25rem;
}

.table-row-key-card {
  display: flex;
  margin-right: 0.5rem;
}

.table-row-key-prominent {
  flex: 0.75 1;
}

.table-row-key {
  flex: 0.5 1;
}

.table-row-value-prominent {
  text-align: right;
  padding-left: 0.75rem;
  flex: 0.25 1;
}

.table-row-key-card-icon {
  padding-right: 0.5rem;
}

.Visa {
  width: 37px;
  height: 24px;
}

@media screen and (max-width: 1023px) {
  .table-row-rule {
    padding: 1rem 0 0.688rem;
  }

  .table-row-indented {
    padding-left: 1.125rem;
  }
}

@media screen and (max-width: 767px) {
  .table-row-rule,
  .table-row-rule-prominent {
    padding: 0.75rem 0 0.438rem;
  }

  .table-row-card {
    padding-bottom: 0.25rem;
  }

  .table-row-value {
    padding-left: 0.5rem;
  }

  .table-row-prominent {
    padding: 0 0 0.25rem;
  }

  .table-row-indented {
    padding-left: 1.25rem;
  }
}

.table-section-header {
  padding: 1rem 0;
}

.table-section-header-description {
  margin-top: 0.25rem;
}

@media screen and (max-width: 767px) {
  .table-section-header {
    padding: 0.75rem 0;
  }
}

.table-subtotal {
  display: flex;
  margin: 1.5rem 0 1.75rem;
}

.table-subtotal-right {
  margin-left: auto;
  padding-left: 0.75rem;
}

@media screen and (max-width: 767px) {
  .table-subtotal {
    margin: 1.25rem 0 1.5rem;
  }
}

.table-subsection-header {
  padding: 1.5rem 0 0.75rem;
}

@media screen and (max-width: 767px) {
  .table-subsection-header {
    padding: 1rem 0 0.75rem;
  }
}

.table-subsection-rule {
  padding-bottom: 15px;
  border-bottom: 1px #ccc solid;
}

.special-offers {
  margin-bottom: 1.75rem;
}

.prepaid-card-icon {
  width: 2.313rem;
  height: 1.5rem;
}

.contract-details {
  color: #757575;
}

.table-row-rule-contract {
  padding: 0.5rem 0 0.563rem;
  border-bottom: 1px solid #ccc;
}

.table-centered {
  display: block;
  margin: 0 4.016rem;
}

@media screen and (max-width: 767px) {
  .table-centered {
    margin: 0;
  }
}

.order-details-next-button {
  width: 11.25rem;
}

.order-details-action {
  color: #0d62ff;
}

.order-details-loading {
  padding-top: 2.75rem;
}

.order-details-stepper {
  padding-top: 1.5rem;
}

.order-details-pricing {
  margin-top: 1.75rem;
}

.order-details-contact-us {
  background-color: #f7f7f7;
  text-align: center;
  padding: 1.25rem;
  margin: 3.813rem 0 4rem;
}

.order-details-contact-us-link a {
  color: #000;
}

.order-details-contact-us-link a:hover {
  color: #0a4ecc;
}

@media screen and (max-width: 1023px) {
  .order-details-loading {
    padding-top: 2.25rem;
  }
}

@media screen and (max-width: 767px) {
  .order-details-next-button {
    width: 9.5rem;
  }

  .order-details-loading {
    padding-top: 1.75rem;
  }

  .order-details-contact-us {
    background-color: #f7f7f7;
    text-align: center;
    padding: 1rem;
    margin: 1.75rem 0 3.25rem;
  }
}

.order-details-next-button {
  width: 11.25rem;
}

.order-details-action {
  color: #0d62ff;
}

.order-details-loading {
  padding-top: 2.75rem;
}

.order-details-stepper {
  padding-top: 1.5rem;
}

.terms-legal {
  padding-bottom: 1.5rem;
}

.terms-legal-main {
  padding-top: 3rem;
  padding-bottom: 2.5rem;
}

@media screen and (max-width: 1023px) {
  .order-details-loading {
    padding-top: 2.25rem;
  }
}

@media screen and (max-width: 767px) {
  .order-details-next-button {
    width: 9.5rem;
  }

  .order-details-loading {
    padding-top: 1.75rem;
  }
}

input[type='checkbox']:required {
  pointer-events: none;
}

.table-row-rule:last-child {
  border: none;
}

.table:last-child {
  margin-bottom: 1.75rem;
}

.phone-transfer-terms-and-condition {
  border-top: 0.0625rem #ccc solid;
  margin-bottom: 2.25rem;
}

