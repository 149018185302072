@media screen and (max-width: 680px) {
  .password-essentials-container {
    margin-left: 0;
  }
}

@media screen and (min-width: 681px) and (max-width: 1023px) {
  .password-essentials-container {
    margin-left: 2rem;
  }
}

@media screen and (max-width: 376) {
  .password-essentials-header {
    display: block;
    font-size: 0.875rem;
    font-weight: bold;
    height: 2rem;
    margin-bottom: 1rem;
  }
}

@media screen and (min-width: 1024px) {
  .password-essentials-container {
    margin-left: 2.3125rem;
  }

  .password-essentials-header {
    display: block;
    font-size: 0.875rem;
    font-weight: bold;
    height: 2rem;
    margin-bottom: 1rem;
  }
}

@media screen and (min-width: 376px) and (max-width: 1023px) {
  .password-essentials-header {
    display: block;
    font-size: 0.75rem;
    font-weight: bold;
    height: 2rem;
    margin-bottom: 1rem;
  }
}
