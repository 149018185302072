#main {
  max-width: 100vw;
}

@media screen and (max-width: 376px) {
  .account-reg-container {
    margin: 0 1.5rem 6.25rem;
  }

  .complete-container {
    width: 100%;
  }
}

@media screen and (min-width: 1024px) {
  .account-reg-container {
    margin: 0 18% 9rem;
  }
}

@media screen and (min-width: 376px) and (max-width: 1023px) {
  .account-reg-container {
    margin: 0 2.125rem 9rem;
  }
}

.order-details-next-button {
  width: 11.25rem;
}

.order-details-action {
  color: #0d62ff;
}

.order-details-loading {
  padding-top: 2.75rem;
}

.order-details-stepper {
  padding-top: 1.5rem;
}

@media screen and (max-width: 1023px) {
  .order-details-loading {
    padding-top: 2.25rem;
  }
}

@media screen and (max-width: 767px) {
  .order-details-loading {
    padding-top: 1.75rem;
  }

  .order-details-next-button {
    width: 9.5rem;
  }
}
