.validation-criteria {
  display: flex;
  margin-bottom: 0.875rem;
}

.criteria-img-container {
  margin-right: 1.0625rem;
}

.criteria-image {
  width: 1.125rem;
  height: 1.125rem;
}
