.uploaded-doc-container {
  position: relative;
}

.uploaded-doc-description {
  margin-bottom: 0.75rem;
}

.uploaded-doc-header {
  font-size: 1.25rem;
  font-weight: 700;
}

.uploaded-doc-header-desc {
  font-size: 0.875rem;
}

.uploaded-doc-icon {
  display: flex;
  padding: 0.125rem 1rem 0 1.26rem;
}

.upload-doc-file-name {
  color: #000;
  font-size: 0.875rem;
}

.upload-doc-file-size {
  color: #757575;
  font-size: 0.875rem;
}

.uploaded-doc-info {
  display: flex;
  align-items: flex-start;
  background-color: #f7f7f7;
  width: 100%;
  padding: 0.75rem 0 0.75rem;
  min-height: 3rem;
  border: 1px solid #ccc;
  margin-bottom: 2.75rem;
}

.uploaded-doc-button {
  background-color: #f7f7f7;
  color: #0d62ff;
  font-weight: 700;
  font-size: 16px;
  height: 1.5rem;
  margin-left: auto;
  padding-left: 1rem;
  padding-right: 1rem;
  padding-bottom: 0.875rem;
}
