:root {
  --checkbox-error-color: #d11314;
  --checkbox-size: 1.75rem;
}

.prism-checkbox {
  position: relative;
}

.legal-agreement .prism-checkbox {
  padding-bottom: 2.5rem;
}

.prism-checkbox:hover .label label {
  cursor: pointer;
}

.checkmark {
  height: 13.07px;
  width: 15.87px;
}

.checkmark .checked {
  fill: #000;
}

.label:hover:not(.checked) label .checkmark {
  fill: #c0c0c0;
  opacity: 1;
}

.label:hover label .checkmark .checked {
  fill: #000;
  opacity: 1;
}

.display-none {
  opacity: 0;
}

.custom-checkbox {
  width: var(--checkbox-size);
  height: var(--checkbox-size);
  opacity: 0;
}

.checkbox-border {
  position: absolute;
  width: var(--checkbox-size);
  height: var(--checkbox-size);
  border: 2px solid #414141;
  display: flex;
  align-items: center;
  justify-content: center;
}

.checkbox-error {
  border: 2px solid var(--checkbox-error-color);
}

.checkbox-focus {
  border: 2px solid #21a2fd;
}

.invalid-message {
  color: var(--checkbox-error-color);
  font-family: Montserrat, Arial, 'Helvetica.Neue', Helvetica, sans-serif;
  font-size: 0.688rem;
  letter-spacing: 0.094rem;
  line-height: 1rem;
}

.checkbox-label-text {
  color: rgba(0, 0, 0, 1);
  font-family: 'Lato', Arial, 'Helvetica Neue', Helvetica, sans-serif;
  font-size: 1rem;
  font-weight: 400;
  letter-spacing: 0.031rem;
  line-height: 1.5rem;
  margin-left: 1.25rem;
}

.invalid {
  color: var(--checkbox-error-color);
}

.disabled {
  background-color: #e5e5e5;
  border: none;
}
