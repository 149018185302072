.modal {
  position: fixed;
  z-index: 1000;
  left: 0;
  top: 0;
  height: 100%;
  overflow: auto;
  background-color: rgba(0, 0, 0, 0.4);
}

.modal-overlay {
  position: fixed;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
}

.modal-content {
  position: relative;
  background-color: #fefefe;
  margin: 15% auto;
  border: 1px solid #888;
  width: 80%;
  text-align: center;
}

.modal-header {
  color: rgb(0, 0, 0);
  font-size: 32px;
  font-weight: bold;
  text-align: center;
  line-height: 40px;
  margin: 68px 0 20px;
}

.modal-body {
  width: 87.4%;
  margin: 0 auto 68px;
}

.close {
  position: relative;
  width: 1.5rem;
  height: 1.5rem;
  top: 20px;
  right: 20px;
  color: rgb(170, 170, 170);
  float: right;
  font-size: 28px;
  font-weight: bold;
}

.close:hover,
.close:focus {
  color: black;
  text-decoration: none;
  cursor: pointer;
}

.setup-container {
  margin: 0 0 2.5rem;
}

.setup-text {
  margin-top: 3.25rem;
}

.setup-link {
  display: contents;
  color: #0d62ff;
  text-decoration: none;
  text-align: left;
}

.invite-reg-commercial-link-container {
  display: flex;
  justify-content: flex-start;
}

.invite-reg-commercial-link-container prism-button prism-text {
  text-transform: none;
  font-weight: bold;
}

.setup-img-container {
  margin-left: -0.625rem;
}

.arrow-left-image {
  width: 1.1875rem;
  height: 0.875rem;
  margin-right: 0.625rem;
}

@media screen and (max-width: 1023px) {
  .setup-container {
    margin: 0 0 2.5rem;
  }

  .setup-header-container {
    padding-bottom: 2.1875rem;
    border-bottom: 0.125rem solid gray;
  }

  .setup-header {
    display: block;
    font-size: 2rem;
    letter-spacing: 0;
  }

  .setup-link {
    display: contents;
    color: #0d62ff;
    text-decoration: none;
  }
}

@media screen and (max-width: 767px) {
  .setup-container {
    margin: 0 0 1.25rem;
  }

  .setup-text {
    margin-top: 2.5625rem;
  }

  .setup-link {
    display: contents;
    color: #0d62ff;
    text-decoration: none;
  }
}

@media screen and (max-width: 446px) {
  .invite-reg-commercial-link-container {
    display: flex;
    justify-content: flex-start;
    height: 2.1875rem;
  }
}
