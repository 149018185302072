.account-management-section {
  width: 34.273%;
  padding-right: 1.688rem;
}

.account-management-section:last-child {
  width: 31.452%;
  padding-right: 0;
}

.auto-account-management-section {
  display: flex;
  flex-wrap: wrap;
  flex: 1;
  flex-direction: row;
}

.auto-icon-section {
  width: 5%;
}

.auto-account-management-container {
  padding-bottom: 1.5rem;
  padding-top: 1rem;
}

.autopay-account-address-container {
  display: flex;
  flex-wrap: wrap;
  flex: 1;
  flex-direction: row;
  padding-top: 2rem;
  padding-left: 5%;
}

.title-container {
  margin-left: 5%;
  margin-top: 2rem;
}

.title-description {
  margin-top: 0.3rem;
  margin-bottom: 1.5rem;
}

.auto-description-section {
  padding-left: 5%;
}

.heading-text {
  margin-top: 1.5rem;
  padding-left: 5%;
  padding-bottom: 1rem;
}

.additional-padding {
  padding-bottom: 8px;
}

.quetion-section {
  display: flex;
  flex-direction: row;
  margin-top: 1.5rem;
  margin-bottom: 2.5rem;
  padding-left: 5%;
}

.sales-section {
  padding-left: 0.5rem;
}

.account-address-section {
  padding-right: 4rem;
}

.auto-pay-account-address-section {
  padding-bottom: 2rem;
  padding-right: 6rem;
  width: 33%;
}

.account-management-container {
  display: flex;
  flex-wrap: wrap;
  flex: 1;
  flex-direction: row;
  padding-bottom: 2.5rem;
}

.confirm-button {
  width: 16.3125rem;
  padding-bottom: 2.5rem;
}

.dashboard-button prism-button {
  width: 16.4375rem;
  padding-bottom: 2.5rem;
  padding-top: 2.5rem;
}

.dashboard-button prism-button a.btn.fill.sc-prism-button-commercial {
  background-color: #000;
  border: 0.1875rem solid #000;
}

.dashboard-button prism-button a.btn.fill.sc-prism-button-commercial:not(.disabled):focus,
.dashboard-button prism-button a.btn.fill.sc-prism-button-commercial:not(.disabled):hover,
.dashboard-button prism-button a.btn.fill.sc-prism-button-commercial:not(.disabled):active {
  background-color: #35353b;
  border: 0.1875rem solid #35353b;
}

.dashboard-button a.btn.fill.sc-prism-button-commercial:not(.disabled):focus .text.sc-prism-button-commercial {
  color: #fff;
}

.confirmation-rule {
  border-bottom: 1px #ccc solid;
}

.autopayConfirmationRule {
  border-bottom: 1px #ccc solid;
  margin-left: 5%;
}

.confirmation-info {
  padding: 2.5rem 0;
}

.order-button prism-button {
  width: 16.4375rem;
  padding-bottom: 2.5rem;
  padding-left: 5%;
}

.order-button prism-button a.btn.fill.sc-prism-button-commercial {
  background-color: #000;
  border: 0.1875rem solid #000;
}

.order-button prism-button a.btn.fill.sc-prism-button-commercial:not(.disabled):focus,
.order-button prism-button a.btn.fill.sc-prism-button-commercial:not(.disabled):hover,
.order-button prism-button a.btn.fill.sc-prism-button-commercial:not(.disabled):active {
  background-color: #35353b;
  border: 0.1875rem solid #35353b;
}

.order-button a.btn.fill.sc-prism-button-commercial:not(.disabled):focus .text.sc-prism-button-commercial {
  color: #fff;
}

.account-address-container {
  display: flex;
  flex: 1;
  flex-direction: row;
  padding-top: 2rem;
  padding-bottom: 2rem;
}

.confirmation-navigation-container-header {
  text-align: center;
  padding-top: 2rem;
}

.confirmation-navigation-container {
  display: flex;
  flex-wrap: wrap;
  margin: 3.125rem 2.5rem;
  flex: 1;
  flex-direction: row;
  padding-bottom: 2.5rem;
}

.confirmation-navigation-section {
  width: 34.08%;
  padding-right: 1.625rem;
  display: block;
}

.confirmation-navigation-section:last-child {
  width: 31.84%;
  padding-right: 0;
  display: block;
}

.navigation-space1 {
  padding-bottom: 1.25rem;
}

.navigation-space2 {
  padding-bottom: 0.5rem;
}

.navigation-space3 {
  padding-bottom: 0.75rem;
}

.navigation-link {
  width: fit-content;
}

.navigation-link prism-button a .text.sc-prism-button-commercial.sc-prism-button-commercial-s.block {
  margin: 0;
}

@media screen and (max-width: 767px) {
  .auto-icon-section {
    width: 11%;
  }

  .order-button prism-button,
  .quetion-section,
  .heading-text,
  .auto-description-section {
    padding-left: 11%;
  }

  .autopayConfirmationRule,
  .title-container {
    margin-left: 11%;
  }

  .account-address-container {
    display: block;
    flex-wrap: wrap;
    flex: 1;
    flex-direction: row;
    padding-top: 1.875rem;
  }

  .autopay-account-address-container {
    display: block;
    flex-wrap: wrap;
    flex: 1;
    flex-direction: row;
    padding-top: 2rem;
    padding-left: 11%;
  }

  .auto-pay-account-address-section {
    padding-right: 0;
    width: 100%;
  }

  .account-management-container {
    display: block;
    flex-wrap: wrap;
    flex: 1;
    flex-direction: row;
  }

  .confirmation-navigation-container {
    display: block;
    flex-wrap: wrap;
    margin: 0 1.5rem;
    flex: 1;
    flex-direction: row;
    padding: 0.75rem 0;
  }

  .confirmation-navigation-section,
  .confirmation-navigation-section:last-child {
    width: 100%;
    padding: 1.25rem 0;
    display: block;
  }

  .account-management-section {
    padding-right: 0.313rem;
    width: 100%;
    padding-bottom: 0.938rem;
  }

  .confirm-button {
    width: 100%;
  }

  .dashboard-button prism-button {
    width: 100%;
  }

  .account-management-section:last-child {
    padding-right: 0.313rem;
    width: 100%;
    padding-bottom: 0.938rem;
  }

  .account-address-section {
    display: block;
    padding-right: 0.313rem;
    padding-bottom: 1.5rem;
    width: 100%;
  }
}

@media screen and (min-width: 768px) and (max-width: 1023px) {
  .confirmation-navigation-container {
    display: block;
    flex-wrap: wrap;
    margin: 0 2.125rem;
    flex: 1;
    flex-direction: row;
    padding: 1.25rem 0;
  }

  .confirmation-navigation-section,
  .confirmation-navigation-section:last-child {
    width: 100%;
    display: flex;
    padding: 1.25rem 0;
  }

  .navigation-subsection {
    width: 50%;
  }

  .section1 {
    padding-right: 1.813rem;
  }

  .section2 {
    padding-left: 1.813rem;
  }
}
